
.categoriesBar{
    width: 100vw;
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    background-color: rgba(255, 255, 255, 0);
}

.categoriesBar button{
    background-color: var(--secondary-dark-gray);
    padding: 0.5em 1em;
    margin: 0 .5em;
    border: none;
    border-radius: 20px;
    color: var(--primary-white);
    cursor: pointer;
}

button.isSelected{
    border: 2px solid var(--primary-white);
}