.Model{
    width: 100%;
    height: 50em;
    margin-bottom: 10em;
    display: flex;
}

.modelContainer{
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
}

.modelOptions{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 30%;
}

.modelOptions button{
    width: 70%;
    padding: 1em;
    margin: 1em 0;
    border: none;
    background-color: var(--primary-black);
    color: var(--primary-white);
}

.modelOptions button:hover{
    background-color: var(--primary-blue);
}