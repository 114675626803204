.topicItemContainer{
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: var(--primary-white);
    overflow: hidden;
    -webkit-box-shadow: 1px 2px 2px 0px var(--secondary-gray);
    -moz-box-shadow: 1px 2px 2px 0px var(--secondary-gray);
    box-shadow: 1px 2px 2px 0px var(--secondary-gray);
}

.topicItemContainer button{
    border: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.imageContainer{
    width: 100%;
    height: 85%;
    overflow: hidden;
    padding: 0;
}

.titleContainer{
    display: flex;
    height: 15%;
    width: 100%;
    padding: 0 .25em;
    align-items: center;
    justify-content: center;
}


.topicItemContainer img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: var(--primary-white);
    border-radius: 20px 20px 0  0;
}

.titleContainer p {
    font-size:2.5em;
    color: var(--secondary-dark-gray);

}

.categoryContainer{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-around;
    top: .5em;
    left: .5em;
    width: 10em;
    border-radius: 20px;

}

.isFeatured{
    background-color: var(--primary-blue);
}

.default{
    background-color: var(--secondary-dark-gray);

}

.categoryContainer p{
    color: var(--primary-white);
}

.categoryContainer span{
    display: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

/********************
** class variables **
********************/

.featuredCategory, .forYouCategory,.discoverCategory{
    background-color: var(--primary-blue);
}

.featuredCategory span, .forYouCategory span,.discoverCategory span{
    display: block;
}

.featuredCategory span{
    background-color: var(--tertiary-pink);
}

.forYouCategory span{
    background-color: var(--tertiary-green);
}

.discoverCategory span{
    background-color: var(--tertiary-orange);
}

.gridDefault{
    grid-column: span 1;
    grid-row: span 1;
}

.gridDefault .titleContainer > p{
    font-size: 10pt;
}

.twoColumn{
    grid-column: span 2;
}

.threeColumn{
    grid-column: span 3;
}

.twoRow{
    grid-row: span 2;
}
