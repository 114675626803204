.introductionContainer{
    display: flex;
    position: relative;
    align-items: center;
    width: 90vw;
    height: 40vh;
}

.introductionContainer p{
    align-self: center;
    width: 40%;
    margin-right: 5em;
    font-weight: bold;
    color: var(--secondary-dark-gray);
}

.imageContainer{
    width: 60%;
    height: 100%;
}

.introductionContainer img{
    width: 100%;
    object-fit: cover;
}

.introductionContainer h1{
    position: absolute;
    color: var(--primary-white);
    font-size: 250pt;
    font-weight: 400;
    bottom: -25rem;
    left: 15%;
}


