.homePageContainer{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
}

.topicItemsContainer{
    display: grid;
    grid-template-columns: repeat(4,auto);
    grid-auto-rows: 20em;
    gap: 1em;
    grid-auto-flow: dense;
    z-index: 1;
    margin-bottom: 10em;

}

.homePageContainer{
        padding-top:  36em;
}

.sideContainer{
    width: 20%;
}