/* global css values */
:root{
  --main-font: "poppins regular";
  --primary-blue: #0000CB;
  --primary-white: #ffffff;
  --primary-black: #292B2F;
  --secondary-blue: #000090;
  --secondary-gray: #E4E4E4;
  --secondary-mid-gray: #c9c7c7;
  --secondary-dark-gray: #515054;
  --tertiary-pink: #E517A9;
  --tertiary-orange:#E57222;
  --tertiary-green:#17E569;
}
