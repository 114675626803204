.topicReferencesContainer{
    padding: 5em 0 15em;
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: center;
    flex-wrap: wrap;
}

.topicReferencesContainer a{
    text-decoration: none; 
    color: var(--secondary-dark-gray);
    text-align: center;
    font-size: 30pt;
    font-weight: bold;
    padding: 0.5em 2em;
    width: 10em;
    margin: 2em;
    border: 1.5px solid var(--secondary-dark-gray);
}

.topicReferencesContainer a:hover{
    color: var(--primary-white);
    background-color: var(--primary-blue);
}