.topicPage{
    background-color: var(--secondary-gray);
    overflow-x: hidden;
}
.bannerTop{
    background-color: var(--primary-blue);
    position: absolute;
    width: 100%;
    height: 3em;

}


.TopicContainer{
    padding-top: 8em;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.topicChapterContainer{
    padding-top: 50vh;
    background-color: var(--primary-black);
    width: 100%;
    display: flex;
    flex-direction: column;
}



.bannerBottom{
    background-color: var(--primary-blue);
    width: 100%;
    height: 3em;
}

