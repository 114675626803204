.navigation{
    position: fixed;
    z-index: 1;
    top: 0;
    width: 100vw;
}

.navigation > *{
    position: absolute;    
}

.navigationBottomContainer{
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: 1px 2px 2px 0px var(--secondary-gray);
    -moz-box-shadow: 1px 2px 2px 0px var(--secondary-gray);
    box-shadow: 1px 2px 2px 0px var(--secondary-gray);
    background-color: var(--primary-blue);
}

.navigationBottomContainer img.logo{
    width: 65vw;
    padding-bottom: 2em;
}

div.navigationTopLeftContainer *, div.navigationTopRightContainer *{
    margin: 0 .5em;  
    border: none;
    background-color: var(--primary-blue);
    color: var(--primary-white);
    cursor: pointer;
}

.navigationTopLeftContainer, .navigationTopRightContainer{
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 2.5em;
    height: 2em;


}

.navigationTopLeftContainer{
    left: 3vw;    
}

.faviconLogo{
    width: fit-content;
    -webkit-box-shadow: 0px 0px 6px 1px var(--primary-white);
    -moz-box-shadow: 0px 0px 6px 1px var(--primary-white);
    box-shadow: 0px 0px 6px 1px var(--primary-white);
    border-radius: 5px;
}

.navigationTopRightContainer{
    right: 3vw;
}

.languageOptions{
    border: none;
    background-color: var(--primary-blue);
    color: var(--primary-white);
}

.backgroundColorContainer{
    position: absolute;
    z-index: -1;
    top: 0;
    width: 100vw;
    height: 6em;
    background-color: var(--primary-blue);
}



