.searchBar{
    position: relative;
    display: flex;
    height: 3em;
    width: 50vw;
    align-items: center;
}

.searchBar input{
    align-items: center;
    border: none;
    border-radius: 35px;
    height: 100%;
    min-height: 3em;
    width: 100%;
    padding: 0 5vw;
}

.searchBar label, .searchBar button{
    position: absolute;
    z-index: 1;
}

.searchBar button{
    right: .5em;
    background-color: var(--primary-blue);
    color: var(--primary-white);
    height: 80%;
    width: 20%;
    border-radius: 20px;
    border: none;
}

.searchBar label{
    left: 1.5em;
    width: 1em;
    top: 30%;
}